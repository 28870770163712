<template>
  <ion-page>
    <ion-header>
      <BaseToolbar
        :page-title="$t('views.detail-token')"
        :has-back-button="true"
      />
    </ion-header>
    <ion-content>
      <BaseContent v-if="currentToken" class="detail-token">
        <div class="detail-token__wrapper">
          <TokenItem
            :token="currentToken"
            :show-launch-date="true"
            :show-symbol="true"
            :count="getVoteCountForToken(currentToken)"
          />
          <div class="detail-token__address-btn-wrapper">
            <ion-button
              class="detail-token__address-btn"
              target="_blank"
              size="small"
              fill="clear"
              :href="currentTokenAddress"
            >
              <span
                class="detail-token__address-chain"
                v-text="currentToken.chain"
              />
              <span
                class="detail-token__address-label"
                v-text="currentToken.address"
              />
            </ion-button>
            <ion-icon
              color="medium"
              :icon="copied ? checkmarkCircleOutline : copyOutline"
              @click.stop="copyAddress"
            />
          </div>

          <ion-button
            v-if="currentToken.telegram"
            target="_blank"
            :href="currentToken.telegram"
          >
            <ion-icon
              slot="start"
              :icon="require('@/assets/images/ic_telegram.svg')"
            />
            {{ currentToken.telegram }}
          </ion-button>
          <ion-button
            v-if="currentToken.twitter"
            target="_blank"
            :href="currentToken.twitter"
          >
            <ion-icon slot="start" :icon="logoTwitter" />
            {{ currentToken.twitter }}
          </ion-button>
          <ion-button
            v-if="currentToken.web"
            target="_blank"
            :href="currentToken.web"
          >
            <ion-icon slot="start" :icon="globeOutline" />
            {{ currentToken.web }}
          </ion-button>
          <ion-button
            v-if="currentToken.reddit"
            target="_blank"
            :href="currentToken.reddit"
          >
            <ion-icon slot="start" :icon="logoReddit" />
            {{ currentToken.reddit }}
          </ion-button>
          <ion-button
            v-if="currentToken.discord"
            target="_blank"
            :href="currentToken.discord"
          >
            <ion-icon slot="start" :icon="logoDiscord" />
            {{ currentToken.discord }}
          </ion-button>
        </div>

        <div v-if="!currentToken.preSale" class="detail-token__number-grid">
          <BaseInput
            :label="$t('detail-token.price')"
            name="price"
            :placeholder="$t('detail-token.price')"
            v-model="currentToken.price"
            :readonly="true"
          />
          <BaseInput
            :label="$t('detail-token.market-cap')"
            name="marketCap"
            :placeholder="$t('detail-token.market-cap')"
            v-model="currentToken.marketCap"
            :readonly="true"
          />
        </div>

        <div v-else class="detail-token__in-presale-grid">
          <BaseInput
            :label="$t('detail-token.in-presale')"
            name="inPreSale"
            :placeholder="$t('detail-token.in-presale')"
            :value="`${currentToken.preSale}`"
            :readonly="true"
          />
        </div>

        <div class="detail-token__wrapper">
          <p
            class="detail-token__description"
            v-text="currentToken.description"
          />
        </div>

        <ion-button
          v-if="isLogged"
          class="detail-token__edit-btn"
          color="primary"
          expand="block"
          @click="editToken"
          v-text="$t('global.edit')"
        />
      </BaseContent>
    </ion-content>
    <ion-loading
      css-class="loading"
      :is-open="isLoading"
      :message="$t('global.please-wait')"
    />
  </ion-page>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  WritableComputedRef,
} from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonLoading,
} from '@ionic/vue';
import {
  callOutline,
  copyOutline,
  checkmarkCircleOutline,
  globeOutline,
  logoDiscord,
  logoReddit,
  logoTwitter,
} from 'ionicons/icons';
import { useRoute, useRouter } from 'vue-router';
import { Token } from '@/interfaces/token';
import useStore from '@/store';
import { RouteName } from '@/router/route-const';
import TokenItem from '@/components/token-item/TokenItem.vue';
import { ToastDefaultDuration, ToastType, useToast } from '@/hooks/useToast';
import { useI18n } from 'vue-i18n';
import { useVote } from '@/hooks/useVote';
import copyToClipboard from '@/utils/clipboard';
import { ActionTypes } from '@/store/modules/token/actions';

export default defineComponent({
  name: 'DetailToken',
  components: {
    BaseToolbar,
    BaseContent,
    TokenItem,
    BaseInput,
    IonPage,
    IonContent,
    IonHeader,
    IonButton,
    IonIcon,
    IonLoading,
  },
  setup: function () {
    const isLoading = ref(false);

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const { showToast } = useToast();
    const { getVoteCountForToken } = useVote();

    const currentToken: WritableComputedRef<Token | undefined> = computed(
      () => store.getters.currentToken
    );
    const isLogged = computed(() => store.getters.isLogged);
    const currentTokenAddress = computed(() => {
      if (!currentToken.value) return '';
      const { address, chain } = currentToken.value;
      if (chain === 'BSC') {
        return `https://bscscan.com/address/${address}`;
      } else if (chain === 'ETH') {
        return `https://etherscan.io/token/${address}`;
      } else {
        return address;
      }
    });

    const editToken = () => {
      router.push({
        name: RouteName.EDIT_TOKEN,
        params: { hasBackButton: 'true', editMode: 'true' },
      });
    };

    const copied = ref(false);
    const copyAddress = async () => {
      if (!currentToken.value || copied.value) return;
      const { address } = currentToken.value;
      copyToClipboard(address)
        .then(async () => {
          copied.value = true;
          await showToast(t('global.copied'), ToastType.SUCCESS);
          setTimeout(() => {
            copied.value = false;
          }, ToastDefaultDuration);
        })
        .catch(async (error: string) => {
          await showToast(error, ToastType.ERROR);
        });
    };

    const getToken = () => {
      const { id } = route.params;
      if (!id) router.push({ name: RouteName.HOME });
      isLoading.value = true;
      store.dispatch(ActionTypes.setCurrentTokenById, `${id}`).then(() => {
        isLoading.value = false;
      });
    };

    onMounted(() => getToken());

    return {
      isLoading,
      currentToken,
      isLogged,
      copied,
      currentTokenAddress,
      copyOutline,
      checkmarkCircleOutline,
      callOutline,
      globeOutline,
      logoTwitter,
      logoReddit,
      logoDiscord,

      copyAddress,
      editToken,
      getVoteCountForToken,
    };
  },
});
</script>

<style lang="scss">
.detail-token {
  &__wrapper {
    padding: var(--default-padding);
    border-radius: var(--default-border-radius);
    background-color: var(--ion-color-light);
    color: var(--ion-color-dark);
    display: grid;
    grid-auto-flow: row;
    gap: 1em;

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  &__address-btn-wrapper {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    padding: 0 1em;
    background: var(--ion-color-gallery);
    border-radius: 1.5em;
  }

  &__address-btn {
    display: grid;
    grid-auto-flow: column;
  }

  &__address-chain {
    color: var(--ion-color-medium);
    margin-right: 0.5em;
  }

  &__address-chain,
  &__address-label {
    font-weight: var(--fw-bold);
  }

  &__address-label {
    color: var(--ion-color-dark);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__number-grid,
  &__in-presale-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }

  &__in-presale-grid {
    grid-template-columns: 1fr;
    place-items: center;
  }

  &__description {
    margin: 0.25em 0;
    white-space: pre-line;
    user-select: text;
  }

  &__edit-btn {
    margin-top: 1em;
  }
}

.loading {
  --background: var(--ion-color-secondary);
}
</style>
