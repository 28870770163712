declare const window: any;

export default function copyToClipboard(textToCopy: string) {
  return new Promise<any>((resolve, reject) => {
    if (window.clipboardData && window.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      const rval = window.clipboardData.setData('Text', textToCopy);
      resolve(rval);
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy')
    ) {
      const textarea = document.createElement('textarea');
      textarea.textContent = textToCopy;
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        // Security exception may be thrown by some browsers.
        document.execCommand('copy');
        resolve(true);
      } catch (ex) {
        reject(ex);
      } finally {
        document.body.removeChild(textarea);
      }
    } else {
      reject(new Error('Could not copy to clipboard'));
    }
  });
}
