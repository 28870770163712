
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  WritableComputedRef,
} from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonLoading,
} from '@ionic/vue';
import {
  callOutline,
  copyOutline,
  checkmarkCircleOutline,
  globeOutline,
  logoDiscord,
  logoReddit,
  logoTwitter,
} from 'ionicons/icons';
import { useRoute, useRouter } from 'vue-router';
import { Token } from '@/interfaces/token';
import useStore from '@/store';
import { RouteName } from '@/router/route-const';
import TokenItem from '@/components/token-item/TokenItem.vue';
import { ToastDefaultDuration, ToastType, useToast } from '@/hooks/useToast';
import { useI18n } from 'vue-i18n';
import { useVote } from '@/hooks/useVote';
import copyToClipboard from '@/utils/clipboard';
import { ActionTypes } from '@/store/modules/token/actions';

export default defineComponent({
  name: 'DetailToken',
  components: {
    BaseToolbar,
    BaseContent,
    TokenItem,
    BaseInput,
    IonPage,
    IonContent,
    IonHeader,
    IonButton,
    IonIcon,
    IonLoading,
  },
  setup: function () {
    const isLoading = ref(false);

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const { showToast } = useToast();
    const { getVoteCountForToken } = useVote();

    const currentToken: WritableComputedRef<Token | undefined> = computed(
      () => store.getters.currentToken
    );
    const isLogged = computed(() => store.getters.isLogged);
    const currentTokenAddress = computed(() => {
      if (!currentToken.value) return '';
      const { address, chain } = currentToken.value;
      if (chain === 'BSC') {
        return `https://bscscan.com/address/${address}`;
      } else if (chain === 'ETH') {
        return `https://etherscan.io/token/${address}`;
      } else {
        return address;
      }
    });

    const editToken = () => {
      router.push({
        name: RouteName.EDIT_TOKEN,
        params: { hasBackButton: 'true', editMode: 'true' },
      });
    };

    const copied = ref(false);
    const copyAddress = async () => {
      if (!currentToken.value || copied.value) return;
      const { address } = currentToken.value;
      copyToClipboard(address)
        .then(async () => {
          copied.value = true;
          await showToast(t('global.copied'), ToastType.SUCCESS);
          setTimeout(() => {
            copied.value = false;
          }, ToastDefaultDuration);
        })
        .catch(async (error: string) => {
          await showToast(error, ToastType.ERROR);
        });
    };

    const getToken = () => {
      const { id } = route.params;
      if (!id) router.push({ name: RouteName.HOME });
      isLoading.value = true;
      store.dispatch(ActionTypes.setCurrentTokenById, `${id}`).then(() => {
        isLoading.value = false;
      });
    };

    onMounted(() => getToken());

    return {
      isLoading,
      currentToken,
      isLogged,
      copied,
      currentTokenAddress,
      copyOutline,
      checkmarkCircleOutline,
      callOutline,
      globeOutline,
      logoTwitter,
      logoReddit,
      logoDiscord,

      copyAddress,
      editToken,
      getVoteCountForToken,
    };
  },
});
